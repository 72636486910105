import { useState } from 'react';

const useTableControls = () => {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [sorting, setSorting] = useState([]);

    const handlePaginationChange = (updater) => {
        setPagination((prevPagination) =>
            typeof updater === 'function' ? updater(prevPagination) : updater
        );
    };

    const resetPageIndex = () => {
        setPagination(prevPagination => ({
            ...prevPagination,
            pageIndex: 0,
        }));
    };

    const handleSortingChange = (newSorting) => {
        resetPageIndex();
        setSorting(newSorting);
    };

    const handleFilteringChange = () => {
        resetPageIndex();
    };

    return {
        pagination,
        sorting,
        handlePaginationChange,
        handleSortingChange,
        handleFilteringChange
    };
};

export default useTableControls;
