import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import useSocket from '../hooks/useSocket';

const WhatsAppPage = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    // Fetch initial messages
    useEffect(() => {
        const fetchMessages = async () => {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            try {
                const response = await axios.get(`${baseUrl}/api/whatsapp_messages`);
                setMessages(response.data);
            } catch (error) {
                console.error('Error fetching WhatsApp messages:', error);
            }
        };

        fetchMessages();
    }, []); // Empty dependency array to run only once on mount

    // Use the WebSocket hook
    useSocket(setMessages);

    const handleNewMessageSubmit = async (e) => {
        e.preventDefault();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
            await axios.post(`${baseUrl}/api/addWhatsappMessage`, { value: newMessage });
            setNewMessage('');
        } catch (error) {
            console.error('Error sending new WhatsApp message:', error);
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ marginBottom: '20px' }}>
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}>← Back to CRM</Link>
            </div>
            <h2>WhatsApp Messages</h2>
            <form onSubmit={handleNewMessageSubmit} style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Create a new Whatsapp Message"
                    style={{
                        flex: '1', // Flex-grow to take available space
                        padding: '10px',
                        margin: '0 10px 0 0',
                        borderRadius: '4px',
                        border: '1px solid #ddd'
                    }}
                />
                <button 
                    type="submit"
                    style={{
                        padding: '10px 20px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                >
                    Submit
                </button>
            </form>
            <div>
                <p>To use variables:
                <ol>
                    <li>Ensure you wrap them in curly braces like this: {"{name}"}, {"{resort}"}, and {"{team}"}.</li>
                    <li>Name takes the contacts first name</li>
                    <li>Resort takes the resort listed for them</li>
                    <li>Team is the team member it's allocated to, you'll have to set this 1st as it won't get your username.</li>
                </ol>
                  </p>
                {messages.map((message, index) => (
                    <div key={index} style={{ padding: '5px 0' }}>
                        <strong>{message.id}:</strong> {message.value}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhatsAppPage;
