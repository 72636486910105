import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const inputStyle = {
    width: '300px', 
    
  };

const MonthSelect = ({ months, selectedMonth, onMonthChange }) => {
    return (
        <FormControl margin="normal">
            <InputLabel>Month</InputLabel>
            <Select
                value={selectedMonth}
                label="Month"
                onChange={onMonthChange}
                style={inputStyle}
            >
                {months.map((month, index) => (
                    <MenuItem key={index} value={month}>{month}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MonthSelect;
