import React, { useContext, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { DataContext } from '../utils/DataContext'; 

const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            align: 'center',
            labels: {
                boxWidth: 20,
                padding: 20,
            },
        },
    },
    // ... other options
};


const StatusPieChart = () => {
    const { data } = useContext(DataContext); // Use data from DataContext
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
        }],
    });

    // Process data for Chart.js
    useEffect(() => {
        const statusCounts = data.reduce((acc, cur) => {
            // Check if the status field exists, if not, set it to 'Not Contacted'
            const status = cur.status || 'Not Contacted';
            acc[status] = (acc[status] || 0) + 1;
            return acc;
        }, {});

        const chartData = {
            labels: Object.keys(statusCounts),
            datasets: [
                {
                    data: Object.values(statusCounts),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.7)', // Not contacted - Soft Red
                        'rgba(54, 162, 235, 0.7)', // Contacted - Sky Blue
                        'rgba(255, 206, 86, 0.7)', // Convo - Mellow Yellow
                        'rgba(75, 192, 192, 0.7)', // Quote - Aqua Green
                        'rgba(153, 102, 255, 0.7)', // WF Payment - Light Purple
                        'rgba(255, 159, 64, 0.7)', // Won - Orange Peel
                        'rgba(199, 199, 199, 0.7)', // Bad Data - Grey
                        'rgba(83, 102, 255, 0.7)', // Duplicate - Deep Blue
                        'rgba(255, 99, 71, 0.7)',  // Lost - Tomato Red
                    ],
                    
                    borderColor: [
                        'rgba(255, 99, 132, 1)', // Not contacted - Darker Soft Red
                        'rgba(54, 162, 235, 1)', // Contacted - Darker Sky Blue
                        'rgba(255, 206, 86, 1)', // Convo - Darker Mellow Yellow
                        'rgba(75, 192, 192, 1)', // Quote - Darker Aqua Green
                        'rgba(153, 102, 255, 1)', // WF Payment - Darker Light Purple
                        'rgba(255, 159, 64, 1)', // Won - Darker Orange Peel
                        'rgba(169, 169, 169, 1)', // Bad Data - Darker Grey
                        'rgba(63, 82, 255, 1)', // Duplicate - Darker Deep Blue
                        'rgba(255, 69, 0, 1)',   // Lost - Dark Orange
                    ],
                    
                    borderWidth: 1,
                },
            ],
        };

        setChartData(chartData);
    }, [data]);

    return (
        <div style={{ width: '400px', height: '300px', marginBottom: '60px' }}>
            <Pie data={chartData} options={options} />
        </div>
    );
};

export default StatusPieChart;
