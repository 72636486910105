import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MyTable from './MyTable';
import WhatsAppPage from './WhatsAppPage';
import ReportingPage from './ReportingPage';
import MonthlyPage from './MonthlyPage';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MyTable />} />
            <Route path="/whatsapp" element={<WhatsAppPage />} />
            <Route path="/reporting" element={<ReportingPage />} /> 
            <Route path="/monthly" element={<MonthlyPage />} /> 

        </Routes>
    );
};

export default AppRoutes;
