import React, { useContext, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { DataContext } from '../utils/DataContext';

const ResortPieChart = () => {
    const { data } = useContext(DataContext);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
        }],
    });

    useEffect(() => {
        const resortCounts = data.reduce((acc, cur) => {
            const resort = cur.latestResort || 'Unknown';
            acc[resort] = (acc[resort] || 0) + 1;
            return acc;
        }, {});

        const colors = {
            'Any': 'rgba(255, 99, 132, 0.7)', // Soft Red
            'Ayia Napa': 'rgba(54, 162, 235, 0.7)', // Sky Blue
            'Ibiza': 'rgba(255, 206, 86, 0.7)', // Mellow Yellow
            'Kavos': 'rgba(75, 192, 192, 0.7)', // Aqua Green
            'Magaluf': 'rgba(153, 102, 255, 0.7)', // Light Purple
            'Malia': 'rgba(255, 159, 64, 0.7)', // Orange Peel
            'Malta': 'rgba(199, 199, 199, 0.7)', // Grey
            'Sunny Beach': 'rgba(83, 102, 255, 0.7)', // Deep Blue
            'Zante': 'rgba(255, 99, 71, 0.7)', // Tomato Red
            'Unknown': 'rgba(169, 169, 169, 0.7)' // Light Grey
        };

        const chartData = {
            labels: Object.keys(resortCounts),
            datasets: [
                {
                    data: Object.values(resortCounts),
                    backgroundColor: Object.keys(resortCounts).map(resort => colors[resort] || 'rgba(169, 169, 169, 0.7)'),
                    borderColor: Object.keys(resortCounts).map(resort => colors[resort].replace('0.7', '1')),
                    borderWidth: 1,
                },
            ],
        };
        setChartData(chartData);
    }, [data]);

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                align: 'center',
                labels: {
                    boxWidth: 20,
                    padding: 20,
                },
            },
        },
        // ... other options
    };

    return (
        <div style={{ width: '400px', height: '300px', marginBottom: '60px' }}>
            <Pie data={chartData} options={options} />
        </div>
    );
};

export default ResortPieChart;
