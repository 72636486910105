import React, { useState } from 'react';
import { FormControl, OutlinedInput } from '@mui/material';

const NumberInput = ({ initialValue, onSave, rowId }) => {
    const [value, setValue] = useState(initialValue);

    const handleValueChange = (e) => {
        const newValue = Number(e.target.value);
        if (newValue <= 99) {
            setValue(newValue);
        }
    };

    const handleBlur = () => onSave(rowId, value);

    return (
        <FormControl fullWidth size="small" sx={{ maxWidth: 65 }}>
            <OutlinedInput
                type="number"
                value={value}
                onChange={handleValueChange}
                onBlur={handleBlur}
                size="small"
                sx={{ fontSize: '0.875rem' }}
                inputProps={{ min: 0, max: 99 }} // Optional: HTML5 constraints for the input field
            />
        </FormControl>
    );
};

export default NumberInput;
