import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { DataProvider } from './utils/DataContext';
import AppRoutes from './components/Routes'; // Adjust the path as necessary
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


const AuthenticationButton = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return isAuthenticated ? (
    <button onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </button>
  ) : (
    <button onClick={loginWithRedirect}>Log In</button>
  );
};

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <DataProvider>
        <div className="App">
          {isAuthenticated ? (
            <>
              <AppRoutes />
              <AuthenticationButton />
            </>
          ) : (
            <AuthenticationButton />
          )}
        </div>
      </DataProvider>
    </Router>
  );
}


export default App;
