import React from 'react';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAuth0 } from '@auth0/auth0-react';
import { handleUpdateStatus, handleUpdateTeamMember } from '../services/apiService';

const ContactButton = ({ cell, onUpdateLastContacted }) => {
    const { user } = useAuth0();

    const handleClick = async () => {
        try {
            if (!cell || !cell.original) {
                console.error('Cell or cell.original is undefined:', cell);
                return;
            }

            const id = cell.original._id;

            // Update the CRM Team Member with the logged-in user's name
            if (user && user.name) {
                await handleUpdateTeamMember(id, user.name);
                console.log('CRM Team Member updated to:', user.name);
            }

            // If the current status is 'Not contacted', change it to 'Contacted'
            if (cell.original.status === 'Not contacted') {
                await handleUpdateStatus(id, 'Contacted');
                console.log('Status updated to Contacted');
            }

            // Call onUpdateLastContacted if it's provided
            if (onUpdateLastContacted) {
                await onUpdateLastContacted(id, cell.original.status, user.name);
                console.log('Update last contacted called successfully');
            }
        } catch (error) {
            console.error('Error in handleClick:', error);
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            size="small"
            sx={{ minWidth: '30px', padding: '6px', fontSize: '0.75rem' }}
        >
            <CheckCircleOutlineIcon />
        </Button>
    );
};

export default ContactButton;
