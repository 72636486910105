import React, { useContext, useEffect, useState } from 'react';
import SummaryCard from './SummaryCard';
import MyTable from './MonthlyTable';
import WebsiteTrafficTarget from './WebsiteTrafficTarget'; 
import ConversionRateTarget from './ConversionRateTarget'; 
import MonthSelect from './MonthSelect';
import styled from 'styled-components';
import { DataContext } from '../utils/DataContext';
import { useFetchMonthlyStats } from '../hooks/useFetchData';

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
`;

const PageContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: center; // This will center the items horizontally
  align-items: center; 
  gap: 30px; // Increase gap for more spacing
  margin-bottom: 20px; 
`;

const MonthlyPage = () => {
  const { setMonthlyStats, monthlyStats } = useContext(DataContext);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [months, setMonths] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [websiteTrafficTargetIncrease, setWebsiteTrafficTargetIncrease] = useState(10);
  const [conversionRateTargetIncrease, setConversionRateTargetIncrease] = useState(10);

  useFetchMonthlyStats(setMonthlyStats);

  useEffect(() => {
    if (monthlyStats.length > 0) {
      const uniqueMonths = Array.from(new Set(monthlyStats.map(stat => stat.month))).reverse();
      setMonths(uniqueMonths);
      setSelectedMonth(uniqueMonths[0]);
    }
  }, [monthlyStats]);
  
  useEffect(() => {
    if (selectedMonth) {
      const currentYear = selectedMonth.slice(-4);
      const currentYearStats = monthlyStats.find(stat => stat.month === selectedMonth);
      const previousYear = String(Number(currentYear) - 1);
      const previousYearMonth = selectedMonth.replace(currentYear, previousYear);
      const previousYearStats = monthlyStats.find(stat => stat.month === previousYearMonth);

      if (currentYearStats && previousYearStats) {
        const totalTraffic = Number(previousYearStats.totalTraffic);
        const totalPax = Number(previousYearStats.totalPax);

        const dateString = currentYearStats.days[0].date;
        const reformattedDate = `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`;
        const firstDayDate = new Date(reformattedDate);
        const daysInMonth = new Date(firstDayDate.getFullYear(), firstDayDate.getMonth() + 1, 0).getDate();

        const dailyTrafficTarget = totalTraffic ? Math.round(totalTraffic / daysInMonth) : 'N/A';
        const adjustedDailyTrafficTarget = Math.round(dailyTrafficTarget * (1 + websiteTrafficTargetIncrease / 100));

        const previousYearConversionRate = totalPax / totalTraffic; // totalPax from previousYearStats
        const adjustedConversionRateTarget = (previousYearConversionRate * (1 + conversionRateTargetIncrease / 100)) * 100; // Converted to percentage

        // Adjusted Direct Sales Target calculation
        const adjustedDailySalesTarget = Math.round(adjustedDailyTrafficTarget * (adjustedConversionRateTarget / 100)); // Convert percentage back to ratio

        const formattedMonthData = currentYearStats.days.map(day => {
          const webTrafficVar = Math.round(day.traffic - adjustedDailyTrafficTarget);
          const webTrafficVarPercent = adjustedDailyTrafficTarget ? Math.round((webTrafficVar / adjustedDailyTrafficTarget) * 100) + '%' : 'N/A';

          const salesVar = Math.round(day.pax.Total - adjustedDailySalesTarget);
          const salesVarPercent = adjustedDailySalesTarget ? Math.round((salesVar / adjustedDailySalesTarget) * 100) + '%' : 'N/A';

          const conversionRateActual = day.traffic ? ((day.pax.Total / day.traffic) * 100).toFixed(2) + '%' : 'N/A';
          const conversionRateVar = parseFloat(conversionRateActual) - adjustedConversionRateTarget;
          const conversionRateVarPercent = !isNaN(conversionRateVar) ? Math.round(conversionRateVar) + '%' : 'N/A'; // Rounded to nearest whole number

          const formattedDate = `${day.date.slice(6,8)}/${day.date.slice(4,6)}/${day.date.slice(0,4)}`;

          return {
            date: formattedDate,
            webTrafficActual: day.traffic,
            webTrafficTarget: adjustedDailyTrafficTarget,
            webTrafficVar: webTrafficVar,
            webTrafficVarPercent: webTrafficVarPercent,
            sales2023Actual: day.pax.Total,
            sales2023Target: adjustedDailySalesTarget,
            sales2023Var: salesVar,
            sales2023VarPercent: salesVarPercent,
            conversionRateTarget: adjustedConversionRateTarget.toFixed(2) + '%', // Ensure this is a string with 2 decimal places
            conversionRateActual: conversionRateActual,
            conversionRateVar: conversionRateVar.toFixed(2), // Ensure this is a string with 2 decimal places
            conversionRateVarPercent: conversionRateVarPercent,
          };
        });

        setMonthData(formattedMonthData);
      } else {
        setMonthData([]);
      }
    }
  }, [selectedMonth, monthlyStats, websiteTrafficTargetIncrease, conversionRateTargetIncrease]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <>
      <PageContainer>
        <FlexContainer>
          <SummaryCard title="Total Traffic" value="10K" percentageChange={5} />
          <SummaryCard title="Total Leads" value="2,000" percentageChange={-2} />
          <SummaryCard title="Conversion Rate" value="3.5%" percentageChange={-2} />
          <SummaryCard title="Total Sales" value="$25K" percentageChange={1.5} />
        </FlexContainer>
        <SelectContainer>
          <MonthSelect
            months={months}
            selectedMonth={selectedMonth}
            onMonthChange={handleMonthChange}
          />
          <WebsiteTrafficTarget
            value={websiteTrafficTargetIncrease}
            onChange={e => setWebsiteTrafficTargetIncrease(Number(e.target.value))}
          />
          <ConversionRateTarget
            value={conversionRateTargetIncrease}
            onChange={e => setConversionRateTargetIncrease(Number(e.target.value))}
          />
        </SelectContainer>
        <MyTable data={monthData} />
      </PageContainer>
    </>
  );
};

export default MonthlyPage;
