import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

export const resortOptions = [
    "Any", "Ayia Napa", "Ibiza", "Kavos", "Magaluf", 
    "Malia", "Malta", "Sunny Beach", "Zante"
];

const ResortDropdown = ({ row, onUpdateResort }) => {
    const handleChange = (e) => {
        onUpdateResort(row.original._id, e.target.value).then(() => {
            console.log(`Resort for row ${row.original._id} updated to ${e.target.value}`);
        });
    };

    return (
        <FormControl fullWidth size="small" sx={{ maxWidth: 120 }}>
            <Select
                value={row.original.latestResort || resortOptions[0]}
                onChange={handleChange}  // Updated to use handleChange
                size="small"
                sx={{ fontSize: '0.875rem' }}
            >
                {resortOptions.map((resort) => (
                    <MenuItem key={resort} value={resort}>{resort}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ResortDropdown;
