import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { DataContext } from '../utils/DataContext'; // Adjust this import based on your project structure

const TeamContactReport = () => {
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'Number of Contacts',
            data: [],
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
        }],
    });
    const { data } = useContext(DataContext); 

    useEffect(() => {
        const filteredData = data.filter(item => {
            // Ensure contactLog is present and is an array before calling .some()
            return Array.isArray(item.contactLog) && item.contactLog.some(log => 
                new Date(log.timestamp).toISOString().split('T')[0] === selectedDate);
        });
    
        const teamMemberCounts = filteredData.reduce((acc, cur) => {
            // Again, ensure contactLog is an array before using .forEach()
            if (Array.isArray(cur.contactLog)) {
                cur.contactLog.forEach(log => {
                    if (new Date(log.timestamp).toISOString().split('T')[0] === selectedDate) {
                        acc[log.teamMember] = (acc[log.teamMember] || 0) + 1;
                    }
                });
            }
            return acc;
        }, {});

        const chartData = {
            labels: Object.keys(teamMemberCounts),
            datasets: [{
                label: 'Number of Contacts',
                data: Object.values(teamMemberCounts),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            }],
        };

        setChartData(chartData);
    }, [selectedDate, data]);

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        // You can add more options here if needed
    };

    return (
        <div>
            <input 
                type="date" 
                value={selectedDate} 
                onChange={(e) => setSelectedDate(e.target.value)}
            />
            <div style={{ width: '600px', height: '400px', marginTop: '20px' }}>
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
};

export default TeamContactReport;
