import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

export const teamMemberOptions = [
    "Aine McKenna", "Beth Lay", "Emily Edwards", "Hannah Munson", "Jodie Pilgrim", "Keisha Providence", "Nathan Cable", "Tammi Lees", "Barry Moore"
];

const TeamDropdown = ({ row, currentTeamMember, onUpdateTeamMember }) => {
    return (
        <FormControl fullWidth size="small">
            <Select
                value={currentTeamMember}
                onChange={(e) => onUpdateTeamMember(row.original._id, e.target.value)}
                size="small"
            >
                <MenuItem value="">Select Team Member</MenuItem>
                {teamMemberOptions.map(member => (
                    <MenuItem key={member} value={member}>{member}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TeamDropdown;
