import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const inputStyle = {
  width: '300px',
};

const ConversionRateTarget = ({ value, onChange }) => {
  const options = [];
  for (let i = 0; i <= 100; i += 5) {
    options.push(<MenuItem key={i} value={i}>{i}%</MenuItem>);
  }

  return (
    <FormControl style={inputStyle}>
      <InputLabel id="conversion-rate-target-label">Conversion Rate Increase %</InputLabel>
      <Select
        labelId="conversion-rate-target-label"
        value={value}
        label="Conversion Rate Increase %"
        onChange={onChange}
      >
        {options}
      </Select>
    </FormControl>
  );
};

export default ConversionRateTarget;