import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';

const Styles = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap'); // Using 600 for semi-bold

  padding: 1rem;
  display: block;
  max-width: 100%;
  font-family: 'Poppins', sans-serif;

  .table {
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ccc; // softer border
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); // lighter shadow
    border-radius: 8px;
    ${'' /* overflow: hidden; */}
    background-color: #fff;

    thead {
      position: sticky;
      top: 0;
      z-index: 10;
      tr {
        th {
            text-align: center;
            background-color: #fff; 
        }
        
      }
      tr:first-child {
        th {
          border-bottom: 2px solid #ccc; // softer line
          color: #333; // darker font for contrast
          font-weight: 600; // semi-bold for headers
          padding: 1rem;
        }
        th:nth-child(1),
        th:nth-child(3) {
            background-color: #f9f9f9
        }
      }
      tr:nth-child(2) {
        th {
        border-bottom: 1px solid #ccc; // softer line
        }
        th:nth-child(1),
        th:nth-child(6),
        th:nth-child(7),
        th:nth-child(8),
        th:nth-child(9),
         {
          background-color: #f9f9f9; // Light grey background for the Date section
        }
      }
    }
    thead th:first-child {
      border-top-left-radius: 8px;
    }

    thead th:last-child {
      border-top-right-radius: 8px;
    }

    tbody tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }

    tbody tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }


    tbody {
      tr {
        transition: background-color 0.3s ease; // smooth transition for hover
        td {
          padding: 1rem; // consistent padding
          text-align: center;
          font-weight: 400; // normal font weight for cell content
          border-bottom: 1px solid #eee; // lighter lines
          background-color: #fff; // Slightly darker grey for the other sections data

        }
        td:nth-child(1),
        td:nth-child(6),
        td:nth-child(7),
        td:nth-child(8),
        td:nth-child(9) {
          background-color: #f9f9f9; // Light grey background for the Date column data
        }  
        &:last-child td {
          border-bottom: none;
        }
      }
      tr:hover td {
        background-color: #f7f7f7; // hover effect
      }
    }

    // Removing vertical lines and using padding for column separation
    th, td {
      border-right: none;
      &:not(:last-child) {
        padding-right: 1.5rem;
      }
    }
  }
`;

// Function to get color based on the value
const getColor = (value) => {
  // Ensure that value is within the -100 to 100 range
  const cappedValue = Math.max(-100, Math.min(100, value));
  let color;

  if (cappedValue < 0) {
    // Scale the color from white to red for negative values
    const redIntensity = Math.abs(cappedValue) / 100;
    color = `rgba(255, ${255 - (redIntensity * 255)}, ${255 - (redIntensity * 255)}, 1)`;
  } else if (cappedValue > 0) {
    // Scale the color from white to green for positive values
    const greenIntensity = cappedValue / 100;
    color = `rgba(${255 - (greenIntensity * 255)}, 255, ${255 - (greenIntensity * 255)}, 1)`;
  } else {
    // Neutral color for 0
    color = 'rgba(255, 255, 255, 1)'; // White
  }

  return color;
};





// Function to get the trend indicator based on the value
const getIndicator = (value) => {
  return value >= 0 ? '▲' : '▼';
};

const isFutureDate = (dateString) => {
  const today = new Date();
  const dateParts = dateString.split('/');
  // Assuming the date format is DD/MM/YYYY
  const rowDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return rowDate > today;
};

// React Table component
function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <Styles>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
}


// The component that will render the table
const MyTable = ({ data }) => {
  // Define the columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'WEBSITE TRAFFIC',
        columns: [
          {
            Header: 'Target',
            accessor: 'webTrafficTarget'
          },
          {
            Header: 'Actual',
            accessor: 'webTrafficActual'
          },
          {
            Header: 'VAR',
            accessor: 'webTrafficVar',
            Cell: ({ value, row }) => {
              if (isFutureDate(row.original.date)) {
                return <div>—</div>; // Placeholder for future dates
              }
              // No need for an indicator or color since it's just the difference
              return <div>{value}</div>;
            }
          },
          {
            Header: 'VAR %',
            accessor: 'webTrafficVarPercent',
            Cell: ({ value, row }) => {
              if (isFutureDate(row.original.date)) {
                return <div style={{ backgroundColor:
            'transparent' }}>—</div>; // Use an em dash or any other placeholder
            }
              const numericValue = parseFloat(value);
              const color = getColor(numericValue);
              const indicator = getIndicator(numericValue);
              const displayValue = numericValue < 0 ? value.substring(1) : value;

              return (
                <div style={{ backgroundColor: color }}>
                  {indicator} {displayValue}
                </div>
              );
              
            }
          },
        ],
      },
      {
        Header: 'CONVERSION RATE',
        columns: [
          {
            Header: 'Target',
            accessor: 'conversionRateTarget'
          },
          {
            Header: 'Actual',
            accessor: 'conversionRateActual'
          },
          {
            Header: 'VAR',
            accessor: 'conversionRateVar',
            Cell: ({ value, row }) => {
              if (isFutureDate(row.original.date)) {
                return <div>—</div>; // Placeholder for future dates
              }
              // No need for an indicator or color since it's just the difference
              return <div>{value}</div>;
            }
          },
          {
            Header: 'VAR %',
            accessor: 'conversionRateVarPercent',
            Cell: ({ value, row }) => {
              if (isFutureDate(row.original.date)) {
                return <div style={{ backgroundColor: 'transparent' }}>—</div>;
              }
              const targetValue = parseFloat(row.original.conversionRateTarget);
              const actualValue = parseFloat(row.original.conversionRateActual);
              let percentageVariance = targetValue === 0 ? 0 : ((actualValue - targetValue) / targetValue) * 100;
            
              // Formatting the value to avoid displaying negative sign with the down arrow
              const displayValue = percentageVariance < 0 ? `${percentageVariance.toFixed(0).substring(1)}%` : `${percentageVariance.toFixed(0)}%`;
              const indicator = getIndicator(percentageVariance);
              const color = getColor(percentageVariance);
            
              return (
                <div style={{ backgroundColor: color }}>
                  {indicator} {displayValue}
                </div>
              );
            }
            
          }
        ],
      },
      {
        Header: 'DIRECT SALES',
        columns: [
          {
            Header: 'Target',
            accessor: 'sales2023Target'
          },
          {
            Header: 'Actual',
            accessor: 'sales2023Actual'
          },
          {
            Header: 'VAR',
            accessor: 'sales2023Var',
            Cell: ({ value, row }) => {
              if (isFutureDate(row.original.date)) {
                return <div>—</div>; // Placeholder for future dates
              }
              // No need for an indicator or color since it's just the difference
              return <div>{value}</div>;
            }
          },
          
          {
            Header: 'VAR %',
            accessor: 'sales2023VarPercent',
            Cell: ({ value, row }) => {
              // Check if the date is in the future
              if (isFutureDate(row.original.date)) {
                return <div style={{ backgroundColor:
            'transparent' }}>—</div>; // Use an em dash or any other placeholder
            }
              const numericValue = parseFloat(value);
              const color = getColor(numericValue);
              const indicator = getIndicator(numericValue);
              const displayValue = numericValue < 0 ? value.substring(1) : value;
              return (
                <div style={{ backgroundColor: color }}>
                  {indicator} {displayValue}
                </div>
              );
            }
          },
          
        ],
      },
    ],
    []
  );

  return (
    <div>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default MyTable;
