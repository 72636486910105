import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

export const statusOptions = [
    "Not contacted", "Contacted", "Convo", "Quote", 
    "WF Payment", "Don't have Whatsapp", "Won", "Bad Data", "Duplicate", "Lost"
];

const StatusDropdown = ({ row, column, currentStatus, onUpdateStatus }) => {
    return (
        <FormControl fullWidth size="small" sx={{ maxWidth: 120 }}>
            <Select
                id="status-select"
                value={currentStatus}
                onChange={(e) => onUpdateStatus(row.original._id, e.target.value)}
                size="small"
                sx={{ fontSize: '0.875rem' }}
            >
                {statusOptions.map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default StatusDropdown;