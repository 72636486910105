import { useEffect } from 'react';
import axios from 'axios';

const useFetchData = (setData, setCashTakenData) => {
    useEffect(() => {
        const fetchData = async () => {
            const startTime = Date.now(); // Start time
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            try {
                console.log('Starting data fetch...');
                const [dataResponse, cashTakenResponse] = await Promise.all([
                    axios.get(`${baseUrl}/api/data`),
                    axios.get(`${baseUrl}/api/cash-taken`)
                ]);

                setData(dataResponse.data);
                setCashTakenData(cashTakenResponse.data);
                const endTime = Date.now(); // End time
                console.log(`Data fetched in ${endTime - startTime}ms`);
            } catch (error) {
                console.error('Error fetching data:', error);
                const endTime = Date.now();
                console.log(`Data fetch failed after ${endTime - startTime}ms`);
            }
        };

        fetchData();
    }, [setData, setCashTakenData]);
};

export default useFetchData;

export const useFetchMonthlyStats = (setMonthlyStats) => {
    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        axios.get(`${baseUrl}/api/monthly-stats`)
            .then(response => {
                console.log('Monthly stats:', response.data);
                setMonthlyStats(response.data);
            })
            .catch(error => console.error('Error fetching monthly stats:', error));
    }, [setMonthlyStats]); // Dependency array includes setMonthlyStats
};