// SummaryCard.js
import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  background-color: #3a4c58; /* This is a blue color, similar to the image */
  color: #fafbff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  width: 250px; /* Adjust the width as necessary */
`;

const CardTitle = styled.h3`
  font-size: 1rem;
  margin: 0;
`;

const CardValue = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  margin: 5px 0;
`;

const CardChange = styled.div`
  font-size: 1rem;
  margin-top: 5px;
  ${({ isPositive }) => isPositive ? `
    color: #4caf50; /* Green for positive change */
  ` : `
    color: #f44336; /* Red for negative change */
  `}
`;

// Add a prop for percentage change and a flag for indicating the direction of the change
const SummaryCard = ({ title, value, percentageChange }) => {
  const isPositive = percentageChange >= 0;
  return (
    <CardContainer>
      <CardTitle>{title}</CardTitle>
      <CardValue>{value}</CardValue>
      <CardChange isPositive={isPositive}>
        {isPositive ? '▲' : '▼'} {Math.abs(percentageChange)}% on 2023
      </CardChange>
    </CardContainer>
  );
};

export default SummaryCard;
