import React, { useContext } from 'react';
import { DataContext } from '../utils/DataContext'; // Adjust the path as necessary
import './ReportingPage.css';

const CashTakenTable = () => {
    const { cashTakenData } = useContext(DataContext);

    return (
        <div className="tables-container">
            <h2>Cash Taken Data</h2>
            <table className="cash-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Daily Amount</th>
                        <th>Total Paid Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {cashTakenData && cashTakenData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.runTime ? new Date(item.runTime).toLocaleDateString() : 'N/A'}</td>
                            <td>{item.dailyAmount ? item.dailyAmount.toFixed(2) : '0.00'}</td>
                            <td>{item.totalPaidAmount ? item.totalPaidAmount.toFixed(2) : '0.00'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CashTakenTable;
