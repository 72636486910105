import { useEffect } from 'react';
import io from 'socket.io-client';

const useSocket = (setWhatsappMessages, setData, setMonthlyStats) => {
    useEffect(() => {
        const baseUrl = process.env.NODE_ENV === 'production'
                        ? 'wss://partyhardtravel.duckdns.org' // Replace with your actual WebSocket URL
                        : 'http://localhost:3001';
        const socket = io(baseUrl);
        // uncomment for online version
        // const socket = io('wss://partyhardtravel.duckdns.org'); // Without specifying port 3001

        // comment for online version
        // const socket = io('http://localhost:3001'); // Without specifying port 3001

        socket.on('connect', () => console.log('WebSocket Connected'));
        socket.on('connect_error', (error) => console.log('WebSocket Connect Error:', error));
        socket.on('disconnect', (reason) => console.log('WebSocket Disconnected:', reason));
        socket.on('statusUpdate', (updatedRecord) => {
            setData((currentData) =>
                currentData.map((record) =>
                    record._id === updatedRecord._id ? updatedRecord : record
                )
            );
        });
        socket.on('resortUpdate', (updatedRecord) => {
            setData((currentData) => 
                currentData.map((record) =>
                    record._id === updatedRecord._id ? updatedRecord : record
                )
            )
        });
        
        
        socket.on('paxUpdate', (updatedRecord) => {
            setData((currentData) =>
                currentData.map((record) =>
                    record._id === updatedRecord._id ? updatedRecord : record
                )
            );
        });
        socket.on('teamMemberUpdate', (updatedRecord) => {
            setData((currentData) =>
                currentData.map((record) =>
                    record._id === updatedRecord._id ? updatedRecord : record
                )
            );
        });
        socket.on('lastContactedUpdate', (updatedRecord) => {
            setData((currentData) =>
                currentData.map((record) =>
                    record._id === updatedRecord._id ? updatedRecord : record
                )
            );
        });
        // Listen for new WhatsApp message event
        socket.on('newWhatsappMessage', (newMessage) => {
            setWhatsappMessages((currentMessages) => {
                // Check if the new message already exists in the current messages
                if (!currentMessages.some(message => message.id === newMessage.id)) {
                    return [...currentMessages, newMessage];
                } else {
                    return currentMessages;
                }
            });
        });

        socket.on('monthlyStatsUpdate', (updatedStats) => {
            setMonthlyStats(updatedStats);
        });

        return () => {
            socket.off('connect');
            socket.off('connect_error');
            socket.off('disconnect');
            socket.off('statusUpdate');
            socket.off('newWhatsappMessage');
            socket.off('monthlyStatsUpdate');

        };
    }, [setData, setWhatsappMessages, setMonthlyStats]);

};

export default useSocket;