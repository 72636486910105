import React, { createContext, useState } from 'react';
import useFetchData from '../hooks/useFetchData'; 
import useSocket from '../hooks/useSocket'; 

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState([]);
    const [cashTakenData, setCashTakenData] = useState([]); 
    const [whatsappMessages, setWhatsappMessages] = useState([]);
    const [monthlyStats, setMonthlyStats] = useState([]);

    useFetchData(setData, setCashTakenData); 
    useSocket(setWhatsappMessages, setData);
    useSocket(setWhatsappMessages, setData, setMonthlyStats);

    return (
        <DataContext.Provider value={{ 
            data, 
            cashTakenData, 
            whatsappMessages, 
            setData, 
            setWhatsappMessages, 
            setCashTakenData,
            monthlyStats, 
            setMonthlyStats 
        }}>
            {children}
        </DataContext.Provider>
    );
};

