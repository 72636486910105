import Tooltip from '@mui/material/Tooltip';
import { formatDate } from '../utils/utils';
import WhatsAppButton from '../components/WhatsAppButton';
import StatusDropdown from '../components/StatusDropdown';
import { handleUpdateStatus, handleUpdateResort, handleUpdatePax, handleUpdateTeamMember, handleUpdateLastContacted } from '../services/apiService';
import ResortDropdown from '../components/ResortDropdown';
import NumberInput from '../components/NumberInput';
import TeamDropdown from '../components/TeamDropdown'
import ContactButton from '../components/ContactButton'
import { resortOptions } from '../components/ResortDropdown';
import { statusOptions } from '../components/StatusDropdown'
import { teamMemberOptions } from '../components/TeamDropdown';
import EmailCopy from '../components/EmailCopy';

// Define a function to create a tooltip header
const createTooltipHeader = (title, displayText) => (
    <Tooltip title={title}>
        <span>{displayText}</span>
    </Tooltip>
);

// Define a function for default cell rendering with formatting
const defaultCell = (cell, formatFunction) => formatFunction(cell.getValue());

// Column definitions for the MaterialReactTable
export const getTableColumns = (selectedMessage) => [
    {
        accessorKey: 'latestEnquiryDate',
        header: 'Date',
        Header: () => createTooltipHeader("The date of the latest enquiry", "Date"),
        size: 20,
        Cell: ({ cell }) => defaultCell(cell, formatDate)
    },
    {
        accessorKey: 'latestName',
        header: 'Name',
        size: 20,
        enableSorting: false,
        enableColumnActions: false
    },
    {
        accessorKey: 'latestResort',
        header: 'Resort',
        size: 20,
        filterVariant: 'multi-select',
        filterSelectOptions: resortOptions.map(resort => ({ label: resort, value: resort })),
        Cell: ({ row }) => (
            <ResortDropdown
                row={row}
                onUpdateResort={handleUpdateResort}
            />
        ),
    },
    {
        accessorKey: 'basketCountSinceOct2023',
        header: 'Baskets',
        Header: () => createTooltipHeader("Number of baskets made since 01/10/2023", "Baskets"),
        size: 20,
        filterVariant: 'range', // Set range filter variant
    },
    {
        accessorKey: 'latestPax',
        header: 'Pax',
        // Other properties...
        Cell: ({ row }) => (
            <NumberInput
                initialValue={row.original.latestPax}
                onSave={handleUpdatePax}
                rowId={row.original._id}
            />
        ),
        size: 20,
        filterVariant: 'range'
    },
    {
        accessorKey: 'lastBookedSeason',
        header: 'Booked',
        Header: () => createTooltipHeader("The year they last booked. If 0, they haven't booked with us with this email address", "Booked"),
        size: 20,
        filterVariant: 'range'
    },
    {
        accessorKey: 'contacted',
        header: 'Contacted',
        size: 15,
        Cell: ({ row }) => (
            <ContactButton
                cell={row}
                onUpdateLastContacted={handleUpdateLastContacted}
                currentStatus={row.original.status}
                currentTeamMember={row.original.crmTeamMember}
            />
        )
    },
    {
        accessorKey: 'crmLastContacted',
        header: 'Last Contacted',
        size: 20,
        Cell: ({ cell }) => {
            const lastContacted = cell.getValue();
            return lastContacted ? formatDate(lastContacted) : '';
        }
    },    
    {
        accessorKey: 'status',
        header: 'Status',
        size: 20,
        Cell: ({ row, column }) => (
            <StatusDropdown
                row={row}
                column={column}
                currentStatus={row.original.status || "Not contacted"}
                onUpdateStatus={handleUpdateStatus}
            />
        ),
        filterVariant: 'multi-select',
        filterSelectOptions: statusOptions.map(option => ({ label: option, value: option })),
    },
    {
        accessorKey: 'crmTeamMember',
        header: 'Team',
        size: 20,
        filterVariant: 'multi-select',
        filterSelectOptions: teamMemberOptions.map(option => ({ label: option, value: option })),
        Cell: ({ row }) => (
            <TeamDropdown
                row={row}
                currentTeamMember={row.original.crmTeamMember || ""}
                onUpdateTeamMember={handleUpdateTeamMember}
            />
        )
    },
    {
        accessorKey: 'latestPhone',
        header: 'Whatsapp',
        Cell: ({ cell }) => <WhatsAppButton cell={cell} message={selectedMessage} />, // Pass the message to WhatsAppButton
        size: 20,
        enableSorting: false,
        enableColumnActions: false
    },
    {
        accessorKey: 'email',
        header: 'Email',
        Cell: ({ cell }) => <EmailCopy email={cell.getValue()} />,
        size: 15,
        enableSorting: false,
        enableColumnActions: false
    },    
    {
        accessorKey: 'countSinceOct2023',
        header: 'Data 24',
        Header: () => createTooltipHeader("Number of times they've submitted their data since 01/10/2023", "Data 24"),
        size: 20,
        filterVariant: 'range',
    },
    {
        accessorKey: 'totalEmailCount',
        header: 'Data AT',
        Header: () => createTooltipHeader("Number of times they've submitted their data with us all time", "Data AT"),
        size: 5,
        filterVariant: 'range',
    },
    {
        accessorKey: 'groupBookingCountSinceOct2023',
        header: 'GBR',
        Header: () => createTooltipHeader("Number of group booking requests they've submitted since 01/10/2023", "GBR"),
        size: 5,
        filterVariant: 'range',
    },
    {
        accessorFn: row => row.emailStats ? row.emailStats['2024Opened'] : 0,
        id: 'open24',
        header: 'Open 24',
        size: 15,
        filterVariant: 'range',
    },
    {
        accessorFn: row => row.emailStats ? row.emailStats.totalOpened : 0,
        id: 'openAT',
        header: 'Open AT',
        size: 15,
        filterVariant: 'range',
    },
    {
        accessorFn: row => row.emailStats ? row.emailStats['2024Clicked'] : 0,
        id: 'click24',
        header: 'Click 24',
        size: 15,
        filterVariant: 'range',
    },
    {
        accessorFn: row => row.emailStats ? row.emailStats.totalClicked : 0,
        id: 'clickAT',
        header: 'Click AT',
        size: 15,
        filterVariant: 'range',
    },
    {
        accessorFn: row => row.emailStats ? row.emailStats.unsubscribeOrSpam : 0,
        id: 'unsub',
        header: 'Unsub',
        size: 15,
        filterVariant: 'range',
    },
];
