import React from 'react';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = ({ cell, message }) => {
    const phoneNumber = cell.getValue();

    const handleCopyToClipboard = () => {
        if (!message) {
            alert('No message selected to copy.');
            return;
        }

        // Extract the first name, resort, and team from the row data
        const fullName = cell.row.original.latestName || 'there';
        const firstName = fullName.split(' ')[0]; // Get the first part of the name
        const resort = cell.row.original.latestResort || 'your resort'; // Replace with a default value if needed

        // Extract the first name of the team member
        const teamFullName = cell.row.original.crmTeamMember || 'our team'; // Replace with a default value if needed
        const teamFirstName = teamFullName.split(' ')[0]; // Get the first part of the team member's name

        // Replace placeholders in the message
        let personalizedMessage = message.replace(/{name}/g, firstName);
        personalizedMessage = personalizedMessage.replace(/{resort}/g, resort);
        personalizedMessage = personalizedMessage.replace(/{team}/g, teamFirstName);

        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = personalizedMessage; // Use personalizedMessage here
        document.body.appendChild(textarea);
        textarea.select();

        try {
            const successful = document.execCommand('copy');
            if (!successful) {
                alert('Failed to copy message.');
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            alert('Failed to copy message.');
        }

        document.body.removeChild(textarea); // Clean up
    };

    const copyPhoneNumberToClipboard = () => {
        const textarea = document.createElement('textarea');
        textarea.value = phoneNumber; // Use phoneNumber here
        document.body.appendChild(textarea);
        textarea.select();
    
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
    
        document.body.removeChild(textarea); // Clean up
    };
    

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div onClick={handleCopyToClipboard} style={{ cursor: 'pointer' }}>
                <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="success" startIcon={<WhatsAppIcon />} size="small" sx={{ minWidth: '20px' }} />
                </a>
            </div>
            <span onClick={copyPhoneNumberToClipboard} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                {phoneNumber}
            </span> {/* Clickable phone number */}
        </div>
    );
};

export default WhatsAppButton;
