import React, { useContext, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Select, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from '../utils/DataContext'
import { getTableColumns } from '../utils/tableColumns';
// import useSocket from '../hooks/useSocket';
// import useFetchData from '../hooks/useFetchData';
import useTableControls from '../hooks/useTableControls';

const selectInputStyle = {
    maxWidth: '200px', // Adjust the width as needed
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const selectStyle = {
    marginLeft: '10px',
    height: '36px', // Adjust this value to match your design
    // You may also want to adjust padding to align the text vertically if needed
};

const MyTable = () => {
    const { data, whatsappMessages, setWhatsappMessages } = useContext(DataContext);
    const [selectedMessage, setSelectedMessage] = useState('');

    const {
        pagination,
        sorting,
        handlePaginationChange,
        handleSortingChange,
        handleFilteringChange
    } = useTableControls();

    // Fetch initial WhatsApp messages
    useEffect(() => {
        const fetchWhatsAppMessages = async () => {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            try {
                const response = await axios.get(`${baseUrl}/api/whatsapp_messages`);
                setWhatsappMessages(response.data);
            } catch (error) {
                console.error('Error fetching WhatsApp messages:', error);
            }
        };

        fetchWhatsAppMessages();
    }, [setWhatsappMessages]);

    // Use WebSocket to listen for new WhatsApp messages
    // useSocket(setWhatsappMessages, setData);

    // useFetchData(setData);

    const columns = getTableColumns(selectedMessage);

    return (
        <MaterialReactTable 
            columns={columns}
            data={data}
            onPaginationChange={handlePaginationChange}
            onSortingChange={handleSortingChange}
            onFilteringChange={handleFilteringChange}
            state={{ pagination, sorting }}
            autoResetPageIndex={false}
            enableStickyHeader
            enableStickyFooter 
            renderTopToolbarCustomActions={() => (
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <Link to="/reporting" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Reporting
                    </Link>
                    <Link to="/whatsapp" style={{ textDecoration: 'none', color: 'inherit', marginLeft: '20px' }}>
                        WhatsApp
                    </Link>
                    <Select
                        value={selectedMessage}
                        onChange={(e) => setSelectedMessage(e.target.value)}
                        style={selectStyle}
                        renderValue={(selected) => (
                            <div style={selectInputStyle}>
                                {selected}
                            </div>
                        )}
                        MenuProps={{ style: { maxHeight: '300px' } }} // This is to control the height of the dropdown menu
                    >
                        {whatsappMessages.map((msg, index) => (
                            <MenuItem key={msg.id || index} value={msg.value}>
                                {msg.value}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )}
        />
    );
};

export default MyTable;
