import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const handleUpdateStatus = async (id, newStatus) => {
    const isBadDataOrDuplicate = newStatus === 'Bad Data' || newStatus === 'Duplicate';
    try {
        const response = await axios.post(`${baseUrl}/api/updateStatus`, {
            id,
            status: newStatus,
            badDataOrDuplicate: isBadDataOrDuplicate,
        });
        if(response.data.success) {
            console.log('Status updated successfully');
        } else {
            console.error('Failed to update status:', response.data.message);
            alert('Failed to update status: ' + response.data.message);
        }
    } catch (error) {
        console.error('Error updating status:', error);
    }
};


export const handleUpdateResort = async (id, newResort) => {
    try {
        const response = await axios.post(`${baseUrl}/api/updateResort`, { id, resort: newResort });
        if(response.data.success) {
            console.log('Resort updated successfully');
        } else {
            console.error('Failed to update Resort:', response.data.message);
            alert('Failed to update Resort: ' + response.data.message);
        }
    } catch (error) {
        console.error('Error updating resort:', error);
    }
};

export const handleUpdatePax = async (id, newPax) => {
    try {
        const response = await axios.post(`${baseUrl}/api/updatePax`, { id, pax: newPax });
        if(response.data.success) {
            console.log('Pax updated successfully');
        } else {
            console.error('Failed to update Pax:', response.data.message);
            alert('Failed to update Pax: ' + response.data.message);
        }
    } catch (error) {
        console.error('Error updating Pax:', error);
    }
};

export const handleUpdateTeamMember = async (id, newTeamMember) => {
    try {
        const response = await axios.post(`${baseUrl}/api/updateTeamMember`, { id, crmTeamMember: newTeamMember });
        if(response.data.success) {
            console.log('CRM Team Member updated successfully');
        } else {
            console.error('Failed to update CRM Team Member:', response.data.message);
            alert('Failed to update CRM Team Member: ' + response.data.message);
        }
    } catch (error) {
        console.error('Error updating CRM Team Member:', error);
    }
};

export const handleUpdateLastContacted = async (id, status, teamMember) => {
    try {
        const response = await axios.post(`${baseUrl}/api/updateLastContacted`, { 
            id,
            status,
            teamMember
        });
        if(response.data.success) {
            console.log('CRM Last Contacted updated successfully');
        } else {
            console.error('Failed to update CRM Last Contacted:', response.data.message);
            alert('Failed to update CRM Last Contacted: ' + response.data.message);
        }
    } catch (error) {
        console.error('Error updating CRM Last Contacted:', error);
    }
};

