import React from 'react';

const EmailCopy = ({ email }) => {
    const copyEmailToClipboard = () => {
        const textarea = document.createElement('textarea');
        textarea.value = email;
        document.body.appendChild(textarea);
        textarea.select();

        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Unable to copy', err);
        }

        document.body.removeChild(textarea);
    };

    return (
        <span onClick={copyEmailToClipboard} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            {email}
        </span>
    );
};

export default EmailCopy;
