import React from 'react';
import StatusPieChart from './statusPieChart';
import TeamContactReport from './TeamContactReport';
import CashTakenTable from './CashTakenChart';
import ResortPieChart from './resortPieChart';
import './ReportingPage.css';

const ReportingPage = () => {
    return (
        <div>
            <h1>Reporting</h1>
            <div className="charts-container">
                <div className="chart">
                    <h2>Status Breakdown</h2>
                    <StatusPieChart />
                </div>
                <div className="chart">
                    <h2>Contacted Per Day</h2>
                    <TeamContactReport />
                </div>
                <div className="chart">
                    <h2>Resorts</h2>
                    <ResortPieChart />
                </div>
                <div className="single-chart">
                    <CashTakenTable />
                </div>
            </div>
        </div>
    );
};

export default ReportingPage;
